<template>
  <div>
    <svg height="0">
      <defs>

        <linearGradient
          id="MyGradient"
          x1="0%"
          y1="-50%"
          x2="100%"
          y2="0%"
        >
          <stop
            offset="0%"
            stop-color="rgb(123, 88, 0)"
          />
          <stop
            offset="100%"
            stop-color="yellow"
          />
        </linearGradient>
      </defs>
    </svg>

    <li class="non_marker">
      <div
        :class="{ bold: isFolder }"
        @click="toggle"
      >
        <span
          v-if="isFolder"
          class="folder-class"
        >
          <feather-icon
            v-if="isOpen"
            class="folder-class"
            variant="warning"
            icon="FolderMinusIcon"
          />
          <feather-icon
            v-else
            class="folder-class"
            variant="warning"
            icon="FolderPlusIcon"
          />
          {{ item.name }}
        </span>
        <span
          v-else
          class="file-class"
        >
          <feather-icon
            class="file-class"
            variant="warning"
            icon="FileIcon"
          />
          <a
            :href="getUrl(item)"
            target="_blank"
          >{{ getName(item) }}</a>
        </span>
      </div>
      <ul
        v-show="isOpen"
        v-if="isFolder"
        class="non_marker"
      >
        <tree-item
          v-for="(child, index) in item.children"
          :key="index"
          class="item"
          :item="child"
        />
      </ul>
    </li>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import TreeItem from '@/views/core/TreeView.vue'

export default {
  name: 'TreeItem',
  components: {
    TreeItem,
    BButton,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    isFolder() {
      return this.item.is_folder && this.item.is_folder != 'false'
    },
  },
  methods: {
    toggle() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen
      }
    },
    getUrl(item) {
      if (!item.path) {
        return ''
      }
      let path = item.path.split('/')
      path = path[path.length - 1]
      return `${process.env.VUE_APP_BASE_API}/api/v1/digital_file/digital_file/record_named/${path}/${item.download_name}`
    },
    getName(item) {
      let { name } = item
      if (item.documental_type && item.documental_type.name) name = `${item.documental_type.name} - ${item.name}`
      return name
    },
  },
}
</script>

<style>
.folder-class {
    display: inline-block;
    padding: 0px;
}
.folder-class svg.feather{
    height: 5em;
    width: 5em;
    color: grey;
    fill: url(#MyGradient);
    /* background-color: rgb(123, 88, 0);
    background-image:
        linear-gradient(135deg, yellow, transparent); */
    stroke-width: 1;
}
#MyGradient {
    background-color: rgb(123, 88, 0);
    background-image:
        linear-gradient(135deg, yellow, transparent);

}
.file-class {
    display: inline-block;
    padding: 0px;
}
.file-class svg.feather{
    height: 5em;
    width: 5em;
}
.non_marker {
    list-style-type: none;
}
</style>
