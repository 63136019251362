<template>
  <div>
    <b-card>
      <b-card-header>
        <div class="text-primary h2">
          <feather-icon
            icon="StarIcon"
            size="25"
            class="mr-0 mr-sm-50"
            variant="primary"
          />
          <span class="d-none d-sm-inline">{{$t(resourcesElementName + '.section.digital_file')}}</span>
        </div>
      </b-card-header>
      <b-card-body>
      
        <b-row>
            <b-col
              cols="12"
              md="12"
            >
            <tree-item
              :item="elementData"
            ></tree-item>
            </b-col>
          </b-row>
      </b-card-body>
    </b-card>

    <b-row class="d-flex justify-content-end">
      <b-button 
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)"
        >
        {{$t('actions.back')}}
      </b-button>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BRow, BCol
} from 'bootstrap-vue'
import VueTreeView from 'vue2-tree-view'
import TreeItem from '@/views/core/TreeView.vue'
import router from "@/router";

export default {
  components: {
    BCard, BButton, BRow, BCol, BCardHeader, BCardBody, VueTreeView, TreeItem
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    router() {
      return router
    },
  }
}
</script>

<style>

</style>
