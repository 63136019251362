<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data')}}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check')}}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-'+RESOURCES_ELEMENT_NAME+'s-list'}"
        >
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list')}}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other')}}
      </div>
    </b-alert>


    <template v-if="elementData">
      <element-edit-tab
        :element-data="elementData"
        :resources-element-name="RESOURCES_ELEMENT_NAME"
        class="mt-2 pt-75"
      />
    </template>
    
  </div>
</template>

<script>
import {
   BAlert, BLink
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import elementStoreModule from '../storeModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ElementEditTab from './EditCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    ElementEditTab: ElementEditTab
  },
  data() {
    return {
      RESOURCES_ELEMENT_NAME: '',
      ELEMENT_APP_STORE_MODULE_NAME: '',
      elementData: {}
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'patient'
    this.ELEMENT_APP_STORE_MODULE_NAME = 'app-di-f-v-' + this.RESOURCES_ELEMENT_NAME
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, elementStoreModule)
    
    // if (router.currentRoute.params.id !== undefined) {
      var data = await this.fetchElement()
      console.log(data)
      this.elementData = this.process_data(data, true)
      console.log(this.elementData)
    // } else {
      // this.elementData = {
      //   name: "My Tree",
      //   children: [
      //     { name: "hello" },
      //     { name: "wat" },
      //     {
      //       name: "child folder",
      //       children: [
      //         {
      //           name: "child folder",
      //           children: [{ name: "hello" }, { name: "wat" }]
      //         },
      //         { name: "hello" },
      //         { name: "wat" },
      //         {
      //           name: "child folder",
      //           children: [{ name: "hello" }, { name: "wat" }]
      //         }
      //       ]
      //     }
      //   ]
      // };
    // }
    
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    process_data(root, is_folder) {
      var ret = {
        path: root.path,
        name: root.name,
        is_folder: is_folder,
        children: []
      }
      if (root.documental_type && root.documental_type.name)
        ret.name = root.documental_type.name + ' - ' + root.name
      if (root.folders) {
        for (const data of root.folders) {
          ret.children.push(this.process_data(data, true))
        }
      }
      if (root.files) {
        for (const data of root.files) {
          ret.children.push(this.process_data(data, false))
        }
      }
      return ret
    },
    async fetchElement() {
      this.newElement = false
      return store.dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_digital_file', { id: router.currentRoute.params.id })
        .then(response => { 
          return response.data 
        })
        .catch(error => {
          if (error.response.status === 404) {
            return {}
          }
        })

    }
  }
}
</script>

<style>

</style>
